import React from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"

import { getHreflangs } from "helpers/application"

const MetaTags = () => {
  const location = useLocation()
  const hreflangs = getHreflangs(
    `${location.pathname}${location.search}${location.hash}`
  )

  return (
    <Helmet>
      <link
        href={`https://${sl.config.site_url}${location.pathname}`}
        rel="canonical"
      />
      {hreflangs.map(({ href, hreflang }) => (
        <link href={href} hrefLang={hreflang} key={hreflang} rel="alternate" />
      ))}
    </Helmet>
  )
}

export default MetaTags
