import _ from "lodash"
import { combineReducers } from "redux"

import { getPref } from "helpers/user-prefs"

import {
  TOGGLE_PANEL_INDEX,
  FORCE_PANEL_INDEX,
  SCROLL_PANEL_INDEX,
  SET_MODAL_PANEL_INDEX,
  HAS_OPENED_PANEL,
  ACTIVATE_PREF_STORE,
  DEACTIVATE_PREF_STORE,
  FLIP_IMAGE,
  TOGGLE_J3D_MODAL,
  TOGGLE_J3D_V2_MODAL,
  UPDATE_IMAGES,
  TRACK_SLIDE,
  LOAD_DEFAULTS,
  LOAD_WIZARD,
  CLEAR_EDIT_FORM_URL,
  SHOW_ERROR,
  ADD_WIZARD_DATA,
  SET_WIZARD_DATA,
  SET_WIZARD_PREF_ORDER,
  SET_EXTRA_WIZARD_PARAMS,
  ADD_COUPON,
  CLEAR_CHOICES,
  UPDATE_CHOICE,
  UPDATE_CHOICE_DIRECTLY,
  REMOVE_CHOICE,
  TOGGLE_ADDON_CHOICE,
  CLEAR_DIAMOND_TMP_DATA,
  SET_SIGNATURE_BASKET,
  UPDATE_PRICING,
  UPDATE_SHIPPING,
  UPDATE_SHIP_EST_VIEW,
  CLEAR_DEFAULT_ENGRAVINGS,
  CLEAR_ENGRAVING_POS,
  SHOW_SHARE_EMAIL_MODAL,
  ADD_TO_CART,
  CANCEL_ADD_TO_CART,
  // UPLOAD_USER_IMAGE,
  UPDATE_PRODUCT_SPECS,
  UPDATE_DISPLAY_STATE,
  UPDATE_INVENTORY,
  ADD_BUILDER_ITEM,
  REMOVE_BUILDER_ITEM,
  FINISH_ADD_BUILDER_ITEM,
  SET_EXTRA_WIZARD_PREF_ORDER,
  PHOTO_BOX_INITIAL_VIEW,
  REPLACE_WIZARD_DATA,
  SET_WIZARD_LOADING,
  PRODUCT_NOT_FOUND,
  PRODUCT_FOUND,
  // SET_CERTIFICATE_VIDEO_DATA,
  SET_WIZARD_HEADER,
  // SET_AS_LOW_AS,
  RESET_PANE_FILTERS,
  SET_BOX_DESIGNS,
  UPDATE_DIAMOND_DATA,
  TOGGLE_BUILD_YOUR_OWN,
  DIAMOND_GOTO_STEP,
  DIAMOND_NEXT_STEP,
  DIAMOND_PREV_STEP,
  DIAMOND_START_OVER,
  UPDATE_TMP_DIAMOND,
  ADD_CHOOSE_STONETYPE_STEP,
  REMOVE_CHOOSE_STONETYPE_STEP,
  ADD_CHOOSE_GEMSTONE_STEP,
  REMOVE_CHOOSE_GEMSTONE_STEP,
  SET_FIXED_ATC,
} from "./actions"

function choices(state = {}, action) {
  let newState = Object.assign({}, state)
  let extraObj = {}
  switch (action.type) {
    case CLEAR_CHOICES:
      // Simple reset to empty object
      return {}
    case LOAD_DEFAULTS:
      newState = action.pds
      return newState
    case UPDATE_CHOICE:
      try {
        if (action.data.value) {
          extraObj.lastValue = state[action.pref]
            ? state[action.pref].value
            : null
        }
      } catch (e) {
        console.error(
          "Caught error in UPDATE_CHOICE, possible undefined action.data",
          e,
          action
        )
      }
      newState[action.pref] = Object.assign(
        {},
        state[action.pref],
        action.data,
        extraObj
      )
      newState[action.pref].error = null
      return newState
    case UPDATE_CHOICE_DIRECTLY:
      newState[action.pref] = Object.assign({}, state[action.pref], action.data)
      return newState
    case REMOVE_CHOICE:
      delete newState[action.pref]
      return newState
    case TOGGLE_ADDON_CHOICE:
      newState.addon = newState.addon || {
        disabled: true, // To skip processing addon choice in other sagas
        pref: "addon",
        value: [],
      }
      newState.addon = {
        ...newState.addon,
        value: newState.addon.value.includes(action.setKey)
          ? newState.addon.value.filter((setKey) => setKey !== action.setKey)
          : [...newState.addon.value, action.setKey],
      }
      return newState
    case ADD_BUILDER_ITEM:
      if (state.cc && state.cc.addingStyleCode) {
        newState.cc = Object.assign({}, state.cc, {
          addingStyleCode: action.style_code,
        })
      } else {
        const currentCount =
          state.cc && state.cc.value && state.cc.value != ""
            ? parseInt(state.cc.value)
            : 0
        newState.cc = Object.assign({}, state.cc, {
          addingStyleCode: action.style_code,
          value: currentCount + 1,
        })
      }
      newState.cc.error = null
      return newState
    case FINISH_ADD_BUILDER_ITEM:
      newState.cc = Object.assign({}, state.cc, { addingStyleCode: false })
      return newState
    case REMOVE_BUILDER_ITEM:
      newState[action.pref] = Object.assign({}, state[action.pref], {
        value: "",
      })
      return newState
    case CLEAR_DEFAULT_ENGRAVINGS:
      Object.keys(state).forEach((key) => {
        if (newState[key].is_default) {
          newState[key] = Object.assign({}, state[key], {
            is_default: false,
            value: "",
          })
        }
      })
      return newState
    case CLEAR_ENGRAVING_POS:
      Object.keys(state).forEach((key) => {
        if (key != action.pref && newState[key].cursor_position) {
          newState[key].cursor_position = null
        }
      })
      return newState
    case SHOW_ERROR:
      newState[action.pref] = Object.assign({}, state[action.pref], {
        error: action.error_text,
      })
      return newState
    default:
      return state
  }
}

const steps = ["d_shape", "d_carat", "choose_stone", "final_diamond"]
function diamondUI(
  state = {
    currentStep: 0,
    steps,
    tmpDiamond: {},
  },
  action
) {
  let newState = Object.assign({}, state)
  let additionalSteps = ["choose_stone"]
  let tmpDiamond = newState.tmpDiamond
  const caratIndex = newState.steps.indexOf("d_carat")
  const stoneTypeIndex = newState.steps.indexOf("choose_stone_type")
  const chooseGemstoneIndex = newState.steps.indexOf("choose_gemstone")

  switch (action.type) {
    case TOGGLE_BUILD_YOUR_OWN:
      if (action.toggle) {
        additionalSteps = ["d_cut", "d_color", "d_clarity", "confirm_stone"]
      }
      newState.steps = [
        ...newState.steps.slice(0, caratIndex + 1),
        ...additionalSteps,
        ...newState.steps.slice(newState.steps.length - 1),
      ]
      newState.currentStep = caratIndex + 1
      return newState
    case ADD_CHOOSE_STONETYPE_STEP:
      if (stoneTypeIndex === -1) {
        newState.steps = ["choose_stone_type", ...newState.steps]
      }
      return newState
    case REMOVE_CHOOSE_STONETYPE_STEP:
      if (stoneTypeIndex !== -1) {
        newState.steps = [...steps]
      }
      return newState
    case ADD_CHOOSE_GEMSTONE_STEP:
      if (chooseGemstoneIndex === -1) {
        newState.steps = [
          "choose_stone_type",
          "choose_gemstone",
          "d_shape",
          "d_carat",
          "confirm_gemstone",
          "final_diamond",
        ]
      }
      return newState
    case REMOVE_CHOOSE_GEMSTONE_STEP:
      if (chooseGemstoneIndex !== -1) {
        if (stoneTypeIndex === -1) {
          newState.steps = steps
        } else {
          newState.steps = ["choose_stone_type", ...steps]
        }
      }
      return newState
    case DIAMOND_GOTO_STEP:
      newState.currentStep = newState.steps.findIndex(
        (step) => step === action.step
      )
      return newState
    case DIAMOND_NEXT_STEP:
      newState.currentStep = newState.currentStep + 1
      return newState
    case DIAMOND_PREV_STEP:
      if (
        newState.currentStep - 1 ===
        newState.steps.findIndex((step) => step === "confirm_stone")
      ) {
        // Skip 'confirm_stone' and go back to 'd_clarity'
        newState.currentStep = newState.currentStep - 2
      } else {
        newState.currentStep = Math.max(0, newState.currentStep - 1)
      }
      return newState
    case DIAMOND_START_OVER:
      newState.currentStep = 0
      return newState
    case UPDATE_TMP_DIAMOND:
      newState = { ...newState, tmpDiamond: { ...tmpDiamond, ...action.data } }
      if (Object.keys(action.data).length === 0) {
        newState.tmpDiamond = {}
      }
      return newState
    default:
      return state
  }
}
function diamondSelector(
  state = {
    d_clarity: {},
    d_color: {},
    d_cut: {},
    d_shape: {},
    d_stone_type: [],
    recommended_stones: [],
    selected_diamond: {},
    signatureBasket: false,
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case UPDATE_DIAMOND_DATA:
      return { ...newState, ...action.data }
    case CLEAR_DIAMOND_TMP_DATA:
      newState.signatureBasket = false
      newState.selected_diamond = {}
      return newState
    case SET_SIGNATURE_BASKET:
      return Object.assign({}, state, { signatureBasket: action.toggle })
    default:
      return state
  }
}

function panel(
  state = {
    activePanelIndex: "",
    modalPanelCallback: false,
    modalPanelIndex: "",
    openedPanelIndex: "",
    openedPanelIndexes: [],
    subActivePanelIndex: {},
    subOpenedPanelIndex: {},
  },
  action
) {
  let panelIndexes = state.openedPanelIndexes.slice()
  switch (action.type) {
    case TOGGLE_PANEL_INDEX:
      if (panelIndexes.includes(action.panel)) {
        panelIndexes = panelIndexes.filter((panel) => panel !== action.panel)
      } else {
        panelIndexes.push(action.panel)
      }

      return Object.assign({}, state, {
        openedPanelIndexes: panelIndexes,
      })
    case FORCE_PANEL_INDEX:
      if (action.prefOf != "") {
        let newState = Object.assign({}, state)
        newState.subActivePanelIndex[action.prefOf] = action.panel
        return newState
      } else {
        return Object.assign({}, state, {
          activePanelIndex: action.panel,
          openedPanelIndexes: [action.panel],
        })
      }
    case SCROLL_PANEL_INDEX:
      return Object.assign({}, state, {
        scrollPanelIndex: action.panel,
      })
    case SET_MODAL_PANEL_INDEX:
      return Object.assign({}, state, {
        modalPanelCallback: action.callback,
        modalPanelIndex: action.panel,
      })
    case HAS_OPENED_PANEL:
      if (action.prefOf != "") {
        let newState = Object.assign({}, state)
        newState.subOpenedPanelIndex[action.prefOf] = action.panel
        return newState
      } else {
        return Object.assign({}, state, { openedPanelIndex: action.panel })
      }
    default:
      return state
  }
}

function wizardUI(
  state = {
    currentPricing: {},
    fixedATC: false,
    isAddingToCart: false,
    lid: "",
    nobox: false,
    prefStore: false,
    productNotFound: false,
    startedPersonalizing: false,
    time: null,
    today: null,
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case ACTIVATE_PREF_STORE:
      return Object.assign({}, state, { prefStore: true })
    case DEACTIVATE_PREF_STORE:
      return Object.assign({}, state, { prefStore: false })
    case UPDATE_PRICING:
      return Object.assign({}, state, { currentPricing: action.data })
    case ADD_TO_CART:
      return Object.assign({}, state, { isAddingToCart: true })
    case CANCEL_ADD_TO_CART:
      return Object.assign({}, state, { isAddingToCart: false })
    case PRODUCT_NOT_FOUND:
      newState.productNotFound = true
      return newState
    case PRODUCT_FOUND:
      newState.productNotFound = false
      return newState
    case SET_EXTRA_WIZARD_PARAMS:
      newState.startedPersonalizing =
        action.params.startedPersonalizing || state.startedPersonalizing
      newState.lid =
        typeof action.params.lid === "undefined" ? state.lid : action.params.lid
      newState.nobox =
        typeof action.params.nobox == "undefined"
          ? state.nobox
          : action.params.nobox
      newState.today = action.params.today || state.today
      newState.time = action.params.time || state.time
      return newState
    case SET_FIXED_ATC:
      newState.fixedATC = action.fixed
      return newState
    default:
      return state
  }
}

const wizardInitialState = {
  addons: [],
  adminMode: false,
  base_ss_price: null,
  coupon: false,
  data: {},
  default_sku: "",
  description: {},
  extraPrefOrder: {},
  filters: {},
  forHer: false,
  forHim: false,
  formUrl: null,
  groupStyleCode: "",
  hasDiamondSelector: false,
  hasFaceComponentOptions: false,
  hasImageOptions: false,
  hasInventory: false,
  hasStampedGallery: false,
  header: null,
  hiddenPrefs: [],
  hidePackaging: false,
  inventory: null,
  loading: "",
  lookSubtitle: "",
  masterStyleCode: null,
  matchingProducts: [],
  metalsIncludeSurchargeFor: [],
  notification: {},
  olid: "",
  orderNumber: "",
  orderParams: null,
  pairsWell: [],
  paneFilters: {},
  prefOrder: [],
  price_and_promo: {},
  priorityBadge: null,
  promo_double_jewls: false,
  promo_double_jewls_messaging: "",
  query_string: "",
  recentlyViewed: [],
  seo_title: "",
  shareUrl: "",
  shipping: {},
  stampedProductId: null,
  title: "",
}

function wizard(state = wizardInitialState, action) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case ADD_WIZARD_DATA:
      // Pane filters are a special case - we keep them disabled until the other selection is complete
      if (
        typeof action.data.filters !== "undefined" &&
        Object.keys(action.data.filters).length > 0
      ) {
        const paneFilterKeys = Object.keys(action.data.filters)
        paneFilterKeys.forEach((key) => {
          if (typeof newState.paneFilters[key] === "undefined") {
            newState.paneFilters[key] = []
          }

          newState.paneFilters[key].push(action.data)
        })

        if (!state.data[action.data.pref]) {
          newState.data[action.data.pref] = Object.assign({}, action.data, {
            disabled: true,
          })
        }
      } else {
        newState.data[action.data.pref] = Object.assign(
          {},
          state.data[action.data.pref],
          action.data
        )
      }

      // Also subscribe any filters into the wizard filters array
      if (typeof action.data.options !== "undefined") {
        const filterKeys = [
          ...new Set(
            _.flattenDepth(
              action.data.options.map((opt) => Object.keys(opt.filters || [])),
              1
            )
          ),
        ]
        newState.filters[action.data.pref] = filterKeys
      }

      return newState
    case RESET_PANE_FILTERS:
      newState.paneFilters = {}
      return newState
    case SET_WIZARD_DATA:
      newState.data[action.data.pref] = action.data
      return newState
    case SET_WIZARD_PREF_ORDER:
      newState.prefOrder = action.order
      return newState
    case SET_EXTRA_WIZARD_PREF_ORDER:
      newState.extraPrefOrder[action.pref] = action.order
      return newState
    case SET_EXTRA_WIZARD_PARAMS:
      newState.title = action.params.title || state.title
      newState.seo_title = action.params.seo_title || state.seo_title
      newState.description = action.params.description || state.description
      newState.hasDiamondSelector =
        typeof action.params.hasDiamondSelector === "undefined"
          ? state.hasDiamondSelector
          : action.params.hasDiamondSelector
      newState.hasFaceComponentOptions =
        typeof action.params.hasFaceComponentOptions === "undefined"
          ? state.hasFaceComponentOptions
          : action.params.hasFaceComponentOptions
      newState.hasImageOptions =
        typeof action.params.hasImageOptions === "undefined"
          ? state.hasImageOptions
          : action.params.hasImageOptions
      newState.hasInventory =
        typeof action.params.hasInventory === "undefined"
          ? state.hasInventory
          : action.params.hasInventory
      newState.hasStampedGallery =
        typeof action.params.hasStampedGallery === "undefined"
          ? state.hasStampedGallery
          : action.params.hasStampedGallery
      newState.hiddenPrefs =
        typeof action.params.hiddenPrefs === "undefined"
          ? state.hiddenPrefs
          : action.params.hiddenPrefs
      newState.hidePackaging =
        typeof action.params.hidePackaging === "undefined"
          ? state.hidePackaging
          : action.params.hidePackaging
      newState.masterStyleCode =
        typeof action.params.masterStyleCode === "undefined"
          ? state.masterStyleCode
          : action.params.masterStyleCode
      newState.groupStyleCode =
        typeof action.params.groupStyleCode === "undefined"
          ? state.groupStyleCode
          : action.params.groupStyleCode
      newState.lookSubtitle =
        typeof action.params.lookSubtitle === "undefined"
          ? state.lookSubtitle
          : action.params.lookSubtitle
      newState.adminMode = action.params.adminMode || state.adminMode
      newState.shareUrl = action.params.shareUrl || state.shareUrl
      newState.price_and_promo =
        action.params.price_and_promo || state.price_and_promo
      newState.formUrl = action.params.formUrl || state.formUrl
      newState.orderParams = action.params.orderParams || state.orderParams
      newState.forHer =
        typeof action.params.forHer === "undefined"
          ? state.forHer
          : action.params.forHer
      newState.forHim =
        typeof action.params.forHim === "undefined"
          ? state.forHim
          : action.params.forHim
      newState.metalsIncludeSurchargeFor =
        action.params.metalsIncludeSurchargeFor ||
        state.metalsIncludeSurchargeFor
      newState.filters = action.params.filters || state.filters
      newState.paneFilters = action.params.paneFilters || state.paneFilters
      newState.primaryTag =
        typeof action.params.primaryTag === "undefined"
          ? state.primaryTag
          : action.params.primaryTag
      newState.priorityBadge =
        typeof action.params.priorityBadge === "undefined"
          ? state.priorityBadge
          : action.params.priorityBadge
      newState.promo_double_jewls =
        typeof action.params.promo_double_jewls === "undefined"
          ? state.promo_double_jewls
          : action.params.promo_double_jewls
      newState.promo_double_jewls_messaging =
        typeof action.params.promo_double_jewls_messaging === "undefined"
          ? state.promo_double_jewls_messaging
          : action.params.promo_double_jewls_messaging
      newState.query_string =
        typeof action.params.query_string === "undefined"
          ? state.query_string
          : action.params.query_string
      newState.addons =
        typeof action.params.addons === "undefined"
          ? state.addons
          : action.params.addons
      newState.stampedProductId =
        typeof action.params.stampedProductId === "undefined"
          ? state.stampedProductId
          : action.params.stampedProductId
      return newState
    case LOAD_WIZARD:
      newState.adminMode = newState.adminMode
        ? newState.adminMode
        : action.data.adminMode
      newState.formUrl = newState.formUrl
        ? newState.formUrl
        : action.data.formUrl
      newState.orderNumber = action.data.orderNumber
      newState.olid = newState.olid ? newState.olid : action.data.olid
      newState.orderParams = newState.orderParams
        ? newState.orderParams
        : action.data.orderParams
      return newState
    case CLEAR_EDIT_FORM_URL:
      newState.formUrl = false
      newState.olid = ""
      return newState
    case ADD_COUPON:
      newState.coupon = action.data
      return newState
    case UPDATE_SHIPPING:
      if (action.data) {
        return Object.assign({}, state, {
          header: {
            ...state.header,
            product_out_of_stock:
              action.data?.main?.view_type == "OUT_OF_STOCK",
          },
          shipping: action.data,
        })
      } else {
        return state
      }
    case UPDATE_SHIP_EST_VIEW:
      newState.shipping = {
        ...newState.shipping,
        estimator: { ...newState.shipping.estimator, view_type: action.view },
      }
      return newState
    case UPDATE_PRODUCT_SPECS:
      newState.description.specs = action.specs
      return newState
    case UPDATE_INVENTORY:
      newState.inventory = action.data
      return newState
    case REPLACE_WIZARD_DATA:
      newState.data = action.data
      return newState
    case SET_WIZARD_HEADER:
      newState.header = action.header
      return newState
    case SET_WIZARD_LOADING:
      newState.loading = action.loading
      return newState
    default:
      return state
  }
}

function previewImageUI(
  state = {
    flipped: false,
    j3dModal: false,
    j3dV2Modal: false,
  },
  action
) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case FLIP_IMAGE:
      return Object.assign({}, newState, { flipped: !state.flipped })
    case TOGGLE_J3D_MODAL:
      return Object.assign({}, newState, { j3dModal: action.toggle })
    case TOGGLE_J3D_V2_MODAL:
      return Object.assign({}, newState, { j3dV2Modal: action.toggle })
    default:
      return newState
  }
}

const previewImageInitialState = {
  data: {
    all_views: [],
    images: {},
  },
  dynamic3DModel: false,
  dynamicGlbModel: false,
  j3d: false,
  j3dV2: false,
  slideIndex: 0,
}

function previewImage(state = previewImageInitialState, action) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case UPDATE_IMAGES:
      newState.j3d = action.data.j3d
      newState.j3dV2 = action.data.j3dV2
      newState.j3dDescription = action.data.j3dDescription
      newState.dynamic3DModel = action.data.dynamic3DModel
      newState.dynamicGlbModel = action.data.dynamicGlbModel
      newState.data = Object.assign({}, state.data, action.data.data)
      return newState
    case TRACK_SLIDE:
      newState.slideIndex =
        typeof action.index === "string"
          ? (action.index = state.data.all_views.findIndex(
              (view) => view === action.index
            ))
          : action.index
      return newState
    default:
      return state
  }
}

function share(state = { emailFormData: {} }, action) {
  switch (action.type) {
    case SHOW_SHARE_EMAIL_MODAL:
      return Object.assign({}, state, { emailFormData: action.data })
    default:
      return state
  }
}

function display(state = {}, action) {
  let newState = Object.assign({}, state)
  switch (action.type) {
    case UPDATE_DISPLAY_STATE:
      newState[action.pref] = Object.assign({}, state[action.pref], action.data)
      return newState
    default:
      return newState
  }
}

function halfModal(
  state = {
    photoBoxViewIndex: 0,
  },
  action
) {
  switch (action.type) {
    case PHOTO_BOX_INITIAL_VIEW:
      return {
        ...state,
        photoBoxViewIndex: action.index,
      }
    default:
      return state
  }
}

function boxDesigns(state = [], action) {
  switch (action.type) {
    case SET_BOX_DESIGNS:
      return Object.assign([], state, action.boxDesigns)
    default:
      return state
  }
}

const productReducer = combineReducers({
  boxDesigns,
  choices,
  diamondSelector,
  diamondUI,
  display,
  halfModal,
  panel,
  previewImage,
  previewImageUI,
  share,
  wizard,
  wizardUI,
})

export {
  boxDesigns,
  panel,
  choices,
  diamondSelector,
  diamondUI,
  previewImage,
  previewImageInitialState,
  previewImageUI,
  wizard,
  wizardInitialState,
  wizardUI,
  share,
  display,
  halfModal,
}

export default productReducer
